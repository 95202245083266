import React, { useEffect, useState } from 'react'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import { cookies } from 'scripts/cookies'
import { navigate, PageProps } from 'gatsby'
import { GetAppResponse } from 'types/ApiResponse'
import { FingerPrint, FingerPrintHash } from 'types/FingerPrint'
import { fullUrl } from 'scripts/fullUrl'
import { ConnectionType } from 'types/Connection'
import { Loading } from 'presentation/components/Loading'
import { getDeviceUuid } from 'scripts/getDeviceUuid'
import { getSubdomain } from 'scripts/getSubdomainForHost'
import { SendLockedSessionTokenToExtension } from 'scripts/sendMessageToExtension'
import { useIpAddress } from 'api/useIpAddress'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { getFingerprintAndHash } from 'scripts/getFingerprintAndHash'
import { SamlAuthPostResponse } from 'types/ApiResponse'
import { getTeam } from 'api/getTeam'
import { sleep } from 'scripts/sleep'
import { axiosClient } from 'scripts/api'
import { useAppByKey } from 'api/useAppId'
import { identitiesCount } from 'api/identitiesCount'
import { ToAccountChooserState, ToTransferState } from 'types/NavigateState'
import { UADataKeys } from 'scripts/UADataKeys'
import { Navigator } from 'types/window'
import { AppType } from 'types/App'
import { getAppByKey } from 'api/getAppByKey'
import { LOCKED_RELAY_STATE_SEPARATOR } from 'scripts/lockedRelayStateSeparator'
import { sentryCaptureMessage } from 'scripts/sentryCaptureMessage'
import { getApps } from 'api/getApps'
import { convertAppsToExtensionApps } from 'scripts/convertAppsToExtensionApps'
import { SendUrlMatchFormBasedAuthParams } from 'scripts/sendMessageToExtension/sendUrlMatchFormBasedAuthParams'

function isUrl(str: string) {
  const pattern = new RegExp('^https?://')
  return !!pattern.test(str)
}

function hasSAML(str: string): boolean {
  return (
    str.includes(LOCKED_RELAY_STATE_SEPARATOR) &&
    str.split(LOCKED_RELAY_STATE_SEPARATOR).length === 3
  )
}

function parseRelayState(relayState: string): {
  isSaml: boolean
  RelayState: string
  appClientKey: string
  samlRequest: string
} {
  if (isUrl(relayState)) {
    // saml-authで始まった時、transferをRelayStateに入れて共通化している
    // URL形式のものがRelayStateに入る
    return {
      isSaml: false,
      RelayState: relayState,
      appClientKey: '',
      samlRequest: '',
    }
  } else if (hasSAML(relayState)) {
    // samlセパレーターを除外し、appClientKeyを生成する
    const [appClientKey, samlRequest, RelayState] = relayState.split(
      LOCKED_RELAY_STATE_SEPARATOR,
    )
    return {
      isSaml: true, // samlリクエストのやり取りをする
      RelayState,
      appClientKey,
      samlRequest,
    }
  } else {
    // NOTE: URL形式でもなく、SAMLのフラグもなければ、RelayStateにはapp_keyのみが入っている
    return {
      isSaml: false,
      RelayState: '',
      appClientKey: relayState,
      samlRequest: '',
    }
  }
}

export const ExternalIdentity = (props: PageProps) => {
  const [openSnackbar] = useSnackbar()
  const [{ ipAddress }] = useIpAddress()
  const [SAMLResponse] = useQueryParam('SAMLResponse', StringParam)

  // NOTE: /saml/auth/:app_keyで外部IDPによるログインをする場合、必要なパラメータは全てrelayStateに入れている。例: ?RelayState=${appKey}--locked-relay-state-separator--${samlRequest}
  const [queryRelayState] = useQueryParam('RelayState', StringParam)

  useEffect(() => {
    ;(async () => {
      if (typeof queryRelayState !== 'string') {
        return
      }

      const subdomain = getSubdomain(location.host)
      if (subdomain === '') {
        return navigate('/', { replace: true })
      }

      const getTeamIdResponse = await getTeam(subdomain)
      if (getTeamIdResponse.status !== 200) {
        return navigate('/404', { replace: true })
      }

      const [fingerprint, fingerprintHash] = await getFingerprintAndHash()
      cookies.setLockedHashToCookie(fingerprintHash)

      const [idpType, idpIdHash] = (props.params['*'] ?? '').split('/')
      const { isSaml, RelayState, appClientKey, samlRequest } =
        parseRelayState(queryRelayState)

      const ua = await (
        navigator as Navigator
      ).userAgentData?.getHighEntropyValues(UADataKeys)

      const params = {
        team_id: getTeamIdResponse.data.team.id,
        fingerprint: fingerprint,
        user_ip: ipAddress,
        hash: fingerprintHash,
        user_agent: navigator.userAgent,
        client_hint: {
          platform: ua?.platform ?? '',
          platform_version: ua?.platformVersion ?? '',
        },
        device_uuid: getDeviceUuid(),
        idp_type: idpType,
        idp_hash: idpIdHash,
        SAMLResponse: SAMLResponse,
        app_key: appClientKey,
        SAMLRequest: samlRequest,
        RelayState: isSaml ? RelayState : '',
      }

      // チームがあるときに、APIへSAMLResponse等バケツリレー
      // APIのルール判定等に合わせて画面遷移など行う
      const { data } = await axiosClient.post<SamlAuthPostResponse>(
        `${fullUrl()}/saml/reauthorize/${idpType}/${idpIdHash}`,
        params,
      )

      if (data.device_uuid) {
        cookies.setDeviceUuidToCookie(data.device_uuid)
      }

      if (data.action === 'allow') {
        if (typeof data.session_token_expired_at_minutes !== 'number') {
          openSnackbar('ログインに失敗しました。', 'denySnackbar')
          return
        }
        const expires = cookies.generateSessionTokenExpires(
          data.session_token_expired_at_minutes,
        )
        cookies.setSessionTokenToCookie(data.session_token ?? '', expires)
        const params = {
          lockedSessionToken: data.session_token,
        }
        const result = await new SendLockedSessionTokenToExtension(
          params,
        ).exec()
        if (result.success) {
          // 何もしない
        } else {
          // 何もしない
        }
        getApps({
          sessionToken: data.session_token ?? '',
        }).then(async (response) => {
          if (response.status !== 200) {
            return
          }
          const extensionApps = convertAppsToExtensionApps(response.data.apps)
          await new SendUrlMatchFormBasedAuthParams({
            apps: extensionApps,
          }).exec()
        })

        // ログインに成功し、URL形式のRelayStateが届いていればそれを優先する
        if (RelayState.length > 0) {
          location.href = RelayState
          return
        }

        if (!isSaml) {
          // ログイン画面からの処理
          const url = new URL(`${location.origin}/apps`)

          // app_keyあればそれを指定
          if (appClientKey.length > 0) {
            url.searchParams.set('app_client_key', appClientKey)
          }

          // アプリ一覧へ遷移
          navigate(url.href.replace(location.origin, ''), {
            state: { snackbarText: 'ログインしました' },
          })
          return
        }

        // saml/auth/:app_keyからの処理
        const getAppByKeyResponse = await getAppByKey({ app_key: appClientKey })
        if (
          getAppByKeyResponse.status !== 200 ||
          typeof getAppByKeyResponse.data.app?.id !== 'number'
        ) {
          openSnackbar('ログインに失敗しました。', 'denySnackbar')
          return
        }

        const appId = getAppByKeyResponse.data.app.id
        const identitiesCountResponse = await identitiesCount({
          app_id: appId,
          session_token: cookies.get('session_token'),
        })
        if (
          identitiesCountResponse.status === 200 &&
          typeof identitiesCountResponse.data.count === 'number' &&
          identitiesCountResponse.data.count > 1
        ) {
          const url = new URL(`${location.origin}/accountChooser`)
          url.searchParams.set('app_id', appId.toString())
          const payload: ToAccountChooserState = {
            samlRequest: samlRequest,
            relayState: RelayState,
          }
          navigate(url.href.replace(location.origin, ''), {
            state: payload,
          })
          return
        }

        const url = new URL(`${location.origin}/transfer`)
        url.searchParams.set('app_id', appId.toString())
        const payload: ToTransferState = {
          samlRequest: samlRequest,
          relayState: RelayState,
        }
        navigate(url.href.replace(location.origin, ''), {
          state: payload,
        })
        return
      } else if (data.action === 'verify') {
        cookies.setLockedEventIdToCookie(data.event_id ?? '')
        const url = new URL(`${location.origin}/verify`)
        if (isSaml) {
          url.searchParams.set('saml', 'true')
        }
        if (appClientKey.length > 0) {
          url.searchParams.set('app_client_key', appClientKey)
        }
        navigate(url.href.replace(location.origin, ''))
        return
      } else if (data.action === 'verify_secret_question') {
        cookies.setLockedEventIdToCookie(data.event_id ?? '')
        const url = new URL(`${location.origin}/verifySecretQuestion`)
        if (isSaml) {
          url.searchParams.set('saml', 'true')
        }
        if (
          typeof data.login_try_token === 'string' &&
          data.login_try_token.length > 0
        ) {
          url.searchParams.set('token', data.login_try_token)
        }
        if (appClientKey) {
          url.searchParams.set('app_client_key', appClientKey)
        }
        navigate(url.href.replace(location.origin, ''))
        return
      } else if (data.action === 'verify_totp') {
        cookies.setLockedEventIdToCookie(data.event_id ?? '')
        const url = new URL(`${location.origin}/verifyTotp`)
        if (isSaml) {
          url.searchParams.set('saml', 'true')
        }
        if (
          typeof data.login_try_token === 'string' &&
          data.login_try_token.length > 0
        ) {
          url.searchParams.set('token', data.login_try_token)
        }
        if (appClientKey) {
          url.searchParams.set('app_client_key', appClientKey)
        }
        navigate(url.href.replace(location.origin, ''))
        return
      } else {
        const message = data.client_error_message || 'ログインに失敗しました。'
        openSnackbar(message, 'denySnackbar')
        await sleep(500)
        navigate('/')
        return
      }
    })()
  }, [queryRelayState])

  return (
    <main className="pb-20 pt-10 w-full flex justify-center">
      <Loading />
    </main>
  )
}
