import { GetAppResponse } from 'types/ApiResponse'
import { axiosClient, useAxios } from 'scripts/api'
import { GetAppByKeyRequest } from 'types/ApiRequest'
import { fullUrl } from 'scripts/fullUrl'

export const getAppByKey = (params: GetAppByKeyRequest) => {
  return axiosClient.get<GetAppResponse>(`${fullUrl()}/apps/show_by_key`, {
    params,
  })
}
