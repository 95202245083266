import { AxiosResponse } from 'axios'
import { fullUrl } from 'scripts/fullUrl'
import { identitiesCountResponse } from 'types/ApiResponse'
import { identitiesCountRequest } from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const identitiesCount = async (
  params: identitiesCountRequest,
): Promise<AxiosResponse<identitiesCountResponse>> => {
  const res = await axiosClient.get<identitiesCountResponse>(
    `${fullUrl()}/apps/${params.app_id}/identities/count`,
    { params },
  )
  return res
}
